
const UnAuthenticatedUser = () => {
  return (
    <h1>
      {/* <p className="m-5 text-center align-middle">You are UnAuthorized user</p> */}
      <p className="m-5 text-center align-middle"></p>
    </h1>
  );
};
export default UnAuthenticatedUser;
