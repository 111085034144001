import { createContext } from "react";
// import KundeWeb from "../Pages/KundeWeb";

export const UtvalgContext = createContext(null);

export const KSPUContext = createContext(null);
export const MainPageContext = createContext(null);

export const KundeWebContext = createContext(null);

// export const TableNewContext = createContext(null);
