// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/PostenSans-Bold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Posten Sans\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"otf\");\n}\n.addressPoint {\n  font-size: 90%;\n  font-weight: normal;\n  color: Green;\n  text-align: left;\n  margin-top: 5px;\n  padding-left: 18px;\n  margin-right: 15px;\n}\n\n.wrongAddressPoint {\n  font-size: 90%;\n  font-weight: normal;\n  color: red;\n  text-align: left;\n  margin-top: 5px;\n  padding-left: 18px;\n  margin-right: 15px;\n}", "",{"version":3,"sources":["webpack://./src/components/last_opp_adressepunkter_resultat/last_opp_adressepunkter_resultat.styles.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,0DAAA;AACJ;AACA;EACI,cAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AAEA;EACE,cAAA;EACA,mBAAA;EACA,UAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;AACF","sourcesContent":["@font-face {\n    font-family: \"Posten Sans\";\n    src: url(\"../../assets/fonts/PostenSans-Bold.otf\") format(\"otf\");\n  }\n.addressPoint{\n    font-size: 90%;\n    font-weight: normal;\n    color: Green;\n    text-align: left;\n    margin-top: 5px;\n    padding-left: 18px;\n    margin-right: 15px;\n\n}\n.wrongAddressPoint{\n  font-size: 90%;\n  font-weight: normal;\n  color: red;\n  text-align: left;\n  margin-top: 5px;\n  padding-left: 18px;\n  margin-right: 15px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
